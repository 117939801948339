import router from '../router'
function formatTime(date) {
	var year = date.getFullYear()
	var month = date.getMonth() + 1
	var day = date.getDate()
	var hour = date.getHours()
	var minute = date.getMinutes()
	var second = date.getSeconds()
	return [year, month, day].map(formatNumber).join('/') + ' ' + [hour, minute, second].map(formatNumber).join(':')
}
/**
 * function: 提取富文本里的纯文本
 * @params 请求参数
 */
function filtersText(val,number) { // 要判断一下,如果是空就返回空字符串,不然会报错
    // console.log(val)
	if (val != null && val != '') {
  //     var reg = /[\u4e00-\u9fa5]/g
  //     var names = val.match(reg)
	 //  console.log(names)
	 //  if(names){
		//  val = names.join('')
		//  return val.substr(0, number?number:80);//获取富文本文字内容的前80个字符
	 //  }else{
		// return val;  
	 //  }
	 const reg = /<.*?>/g;
	 var newval = val.replace(reg, '');
	 return newval;
    }else{
		return ";"
	}
}

/**
 * function: 倒序时间
 * @params 请求参数 时间戳
 */
function beforeTime(dateTimeStamp) {
        var minute = 1000 * 60; //把分，时，天，周，半个月，一个月用毫秒表示
        var hour = minute * 60;
        var day = hour * 24;
        var week = day * 7;
        var halfamonth = day * 15;
        var month = day * 30;
        var year = day * 365;
        var now = new Date().getTime(); //获取当前时间毫秒
        // console.log(now);
        var diffValue = now - dateTimeStamp; //时间差
        if (diffValue < 0) {
          return "";
        }
        var minC = diffValue / minute; //计算时间差的分，时，天，周，月
        var hourC = diffValue / hour;
        var dayC = diffValue / day;
        var weekC = diffValue / week;
        var monthC = diffValue / month;
        var yearC = diffValue / year;
        var result;
        if (yearC >= 1) {
          result = parseInt(yearC) + "年前";
        } else if (monthC >= 1 && monthC <= 12) {
          result = parseInt(monthC) + "月前";
        } else if (weekC >= 1 && weekC <= 4) {
          result = parseInt(weekC) + "周前";
        } else if (dayC >= 1 && dayC <= 7) {
          result = parseInt(dayC) + "天前";
        } else if (hourC >= 1 && hourC <= 24) {
          result = parseInt(hourC) + "小时前";
        } else if (minC >= 1 && minC <= 60) {
          result = parseInt(minC) + "分钟前";
        } else if (diffValue >= 0 && diffValue <= minute) {
          result = "刚刚发布";
        } else {
          var datetime = new Date();
          datetime.setTime(dateTimeStamp);
          var Nyear = datetime.getFullYear();
          var Nmonth =
            datetime.getMonth() + 1 < 10
              ? "0" + (datetime.getMonth() + 1)
              : datetime.getMonth() + 1;
          var Ndate =
            datetime.getDate() < 10
              ? "0" + datetime.getDate()
              : datetime.getDate();
          var Nhour =
            datetime.getHours() < 10
              ? "0" + datetime.getHours()
              : datetime.getHours();
          var Nminute =
            datetime.getMinutes() < 10
              ? "0" + datetime.getMinutes()
              : datetime.getMinutes();
          var Nsecond =
            datetime.getSeconds() < 10
              ? "0" + datetime.getSeconds()
              : datetime.getSeconds();
          result = Nyear + "-" + Nmonth + "-" + Ndate;
        }
        return result;
}

/**
 * function: html剔除富文本标签，留下纯文本
 * @params 请求参数
 */
function getSimpleText(html){
   var re1 = new RegExp("<.+?>","g");//匹配html标签的正则表达式，"g"是搜索匹配多个符合的内容
   var msg = html.replace(re1,'');//执行替换成空字符
   return msg;
}
//小于10的格式化函数（2变成02）
function timeFormat(param) {
  return param < 10 ? '0' + param : param;
}

function formatdateTime(date) {
	var year = date.getFullYear()
	var month = date.getMonth() + 1
	var day = date.getDate()

	var hour = date.getHours()
	var minute = date.getMinutes()
	var second = date.getSeconds()


	return [year, month, day].map(formatNumber).join('-') + ' ' + [hour, minute, second].map(formatNumber).join(':')
}

function formatdatetext(date) {
	var year = date.getFullYear()
	var month = date.getMonth() + 1
	var day = date.getDate()

	var hour = date.getHours()
	var minute = date.getMinutes()
	var second = date.getSeconds()


	return year + "年" + month + "月" + day + "日有效"
}

function formatdate(date) {
	var year = date.getFullYear()
	var month = date.getMonth() + 1
	var day = date.getDate()
	return [year, month, day].map(formatNumber).join('-')
}

function countTime(nowTime,endTime,ifday){
  var time = (endTime-nowTime)/1000;//距离结束的毫秒数
  if(time>0){
    // 获取天、时、分、秒
    let day = parseInt(time / (60 * 60 * 24));
    let hou = parseInt(time % (60 * 60 * 24) / 3600);
    let min = parseInt(time % (60 * 60 * 24) % 3600 / 60);
    let sec = parseInt(time % (60 * 60 * 24) % 3600 % 60);
    hou = timeFormat(hou),
    min = timeFormat(min),
    sec = timeFormat(sec)
    if(ifday){
      return (day>0?day+"天":"")+hou+"小时"+min+"分"+sec+"秒"
    }else{
      return hou+":"+min+":"+sec
    }
  }else{
    return null
  }
}

function formatlastdate(date) {
	var year = date.getFullYear()
	var month = date.getMonth() + 1
	var day = date.getDate() - 1

	var hour = date.getHours()
	var minute = date.getMinutes()
	var second = date.getSeconds()
	return [year, month, day].map(formatNumber).join('-')
}

function getDates(days, todate) {
	var dateArry = [];
	for (var i = 0; i < days; i++) {
		var dateObj = dateLater(todate, i);
		dateArry.push(dateObj)
	}
	return dateArry;
}

function dateLater(dates, later) {
	let dateObj = {};
	let show_day = new Array('周日', '周一', '周二', '周三', '周四', '周五', '周六');
	let date = new Date(dates);
	date.setDate(date.getDate() + later);
	let day = date.getDay();
	let yearDate = date.getFullYear();
	let month = ((date.getMonth() + 1) < 10 ? ("0" + (date.getMonth() + 1)) : date.getMonth() + 1);
	let dayFormate = (date.getDate() < 10 ? ("0" + date.getDate()) : date.getDate());
	dateObj.time = yearDate + '-' + month + '-' + dayFormate;
	dateObj.week = show_day[day];
	return dateObj;
}
/**
 * 判断两个时间大小，和相隔几天
 */
function beCheckTime(beforeTime,afterTime){
	var startTime = new Date(Date.parse(beforeTime.replace(/-/g, "/")));
	var endTime = new Date(Date.parse(afterTime.replace(/-/g, "/")));
	if(startTime.getTime()>=endTime.getTime()){
		var obj = {
			state:false,
			day:0
		}
		return obj;
	}else{
		var obj = {
			state:true,
			day:(endTime.getTime() - startTime.getTime()) / (1000 * 60 * 60 * 24)
		}
		return obj;
	}
}
/**
 * 判断时间是不是中午前
 */
function isMidTime(datetime){
	var startTime = new Date(Date.parse("2020/01/01 "+datetime+":00"));
	var hour = startTime.getHours()
	if(hour<12){
		return true;
	}else{
		return false;
	}
}

function formatNumber(n) {
	n = n.toString()
	return n[1] ? n : '0' + n
}

/**
 * 获取随机数
 */
function getrandom(min,length){
	var index = 0
	if(min){
		index = Math.floor(Math.random() * Number(length)) + Number(min);
	}else{
		index = Math.floor(Math.random() * Number(length));
	}
	return index;
}

/**
 * 将后台带T的时间变成时间戳
 *
 * @date 格式为createDate: "2020-09-25T14:31:58"
 */
function getDateTime(date) {
  var time = new Date(Date.parse(date.replace(/-/g, "/").replace(/T/g, " ")))
  return time
}

/**
 * 将后台带T的时间变成时间戳
 *
 * @date 格式为createDate: "2020-09-25T14:31:58"
 */
function getDateTime2(date) {
  var time = new Date(Date.parse(date.replace(/-/g, "/")))
  return time
}

/**
 * 将后new Date返回年月日时分秒的数组
 *
 * @date 格式为时间new Date
 */
function getDateTimeList(date) {
  var year = date.getFullYear()
  var month = timeFormat(date.getMonth() + 1)
  var day = timeFormat(date.getDate())
  var hour = timeFormat(date.getHours())
  var minute = timeFormat(date.getMinutes())
  var second = timeFormat(date.getSeconds())
  return [year,month,day,hour,minute,second]
}

/**
 * 将后new Date返回增加后数值
 *
 * @date 格式为时间new Date
 */
function getAddDateTime(olddate,number) {
	var date = new Date(olddate.getTime()+1000*number)
	var year = date.getFullYear()
	var month = timeFormat(date.getMonth() + 1)
	var day = timeFormat(date.getDate())
	var hour = timeFormat(date.getHours())
	var minute = timeFormat(date.getMinutes())
	var second = timeFormat(date.getSeconds())
  return [year,month,day,hour,minute,second]
}

/**
 * 计算两点距离
 */
//进行经纬度转换为距离的计算
function Rad(d) {
	return Number(d) * Math.PI / 180.0; //经纬度转换成三角函数中度分表形式。
}
//输出为公里 计算距离，参数分别为第一点的纬度，经度；第二点的纬度，经度
function GetDistance(lat1, lng1, lat2, lng2) {
	var radLat1 = Rad(lat1);
	var radLat2 = Rad(lat2);
	var a = radLat1 - radLat2;
	var b = Rad(lng1) - Rad(lng2);
	var s = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a / 2), 2) +
		Math.cos(radLat1) * Math.cos(radLat2) * Math.pow(Math.sin(b / 2), 2)));
	s = s * 6378.137; // EARTH_RADIUS;
	s = Math.round(s * 10000) / 10000;
	s = s.toFixed(2);
	return s;
}

/**
 * 将后台带T的时间替换掉
 *
 * @date 格式为createDate: "2020-09-25T14:31:58"
 */
function replaceT(date) {
  var time = date.replace(/T/g, " ")
  return time
}

/**
 * 将后台带T的时间替换掉
 *
 * @date 格式为createDate: "2020-09-25T14:31:58"
 */
function replaceTspli(date) {
	if(date){
		var time = date.replace(/T/g, " ")
		return time.split(" ")[0]
	}else{
		return ""
	}
}

//根据开始结束时间判断当前时间在前还是后
function compareTimes(start, end, now) {
	now = now || new Date(); // 如果没有提供 now，则使用当前时间
	if (now < start) {
	    return 'before'; // 在开始时间之前
	} else if (now > end) {
	    return 'end'; // 在结束时间之后
	} else {
	    return 'ing'; // 在开始和结束时间之间
	}
}

/**
 * function:简单path跳转函数
 * @params 请求参数 this.$route.query
 */
function routerPath(url,query){
	// console.log("跳转：",url)
    router.push({
        path: url,
        query: query?query:{}
    })
}

/**
 * function:在另外新建窗口中打开窗口
 * @params 请求参数 
 */
function windowOpen(url){
	// console.log("跳转：",url)
	if(url){
		//window.open(url);
		window.open(url,'_blank');
		//window.location.href = url
	}
}

/**
 * function:kbToMB
 * @params 请求参数 
 */
function kbToMB(kilobytes) {
	var val = kilobytes || 0
	try{
		if(Number(kilobytes)>1024){
			val = (Number(kilobytes) / 1024).toFixed(2) + "MB";
		}else{
			val = val + "KB"
		}
	}catch(e){
		//TODO handle the exception
	}
    return val
}

/**
 * function:oneToqian
 * @params 请求参数 
 */
function oneToqian(valnum) {
	var val = valnum || 0
	try{
		if(Number(valnum)>1000){
			val = parseInt(Number(valnum) / 1000) + "K";
		}
	}catch(e){
		//TODO handle the exception
	}
    return val
}

export default {
	filtersText,
	getSimpleText,
	windowOpen,
	routerPath,
	formatTime,
	formatdateTime,
	formatdate,
	formatdatetext,
	formatlastdate,
	GetDistance,
	beCheckTime,
	isMidTime,
	replaceT,
	getDateTime,
	getDateTime2,
	getrandom,
	countTime,
	beforeTime,
	getDateTimeList,
	getAddDateTime,
	replaceTspli,
	compareTimes,
	kbToMB,
	oneToqian
}