import Vue from 'vue'
import store from '../store/index.js'
import {
    Message,
    MessageBox
} from 'element-ui'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
	//{
	// path: '/',
	// name: 'index',
	// redirect: '/home',
	// component: () => import('@/views/index.vue'),
	// children: [
		
	// ]}
	{
		path: '/',
		name: 'home',
		component: () => import('@/views/home.vue'),
		meta: {
		    keepAlive: true ,// 需要缓存
			ifwhite: true // 白色菜单
		}
	},
	{
		path: '/404',
		name: '404',
		component: () => import('@/views/404.vue')
	},
	{
		path: '/mine',
		name: 'mine',
		component: () => import('@/views/mine.vue')
	},
	{
		path: '/dopay',//支付
		name: 'dopay',
		component: () => import('@/views/dopay.vue')
	},
	{
		path: '/webschool',//网校
		name: 'webschool',
		component: () => import('@/views/webschool.vue')
	},
	{
		
		path: '/class',//课程
		name: 'class',
		component: () => import('@/views/class/class.vue')
	},
	{
		path: '/classDetail',//课程详情
		name: 'classDetail',
		component: () => import('@/views/class/classDetail.vue')
	},
	{
		path: '/questionbank',//题库
		name: 'questionbank',
		component: () => import('@/views/class/questionbank.vue'),
		meta: {
		    title: '河狸训练营',
		    keepAlive: true // 不需要缓存
		}
	},
	{
		path: '/questionDetail',//题目详情
		name: 'questionDetail',
		component: () => import('@/views/class/questionDetail.vue')
	},
	{
		path: '/classQuestionDetail',//课堂作业题目详情
		name: 'classQuestionDetail',
		component: () => import('@/views/class/classQuestionDetail.vue')
	},
	// {
	// 	path: '/determineDetail',//选择判断题题目详情
	// 	name: 'determineDetail',
	// 	component: () => import('@/views/class/determineDetail.vue')
	// },
	{
		path: '/mygroup',//我的小组
		name: 'mygroup',
		component: () => import('@/views/mygroup.vue'),
		meta: {
		    title: '河狸社团',
		    keepAlive: true // 需要缓存
		}
	},
	{
		path: '/mygroupwork',//我的小组作业
		name: 'mygroupwork',
		component: () => import('@/views/mygroupwork.vue')
	},
	{
		path: '/myworkdetail',//我的小组作业详情
		name: 'myworkdetail',
		component: () => import('@/views/myworkdetail.vue')
	},
	{
		path: '/match',//赛事考试
		name: 'match',
		component: () => import('@/views/match.vue'),
		meta: {
		    title: '赛事与考试',
		    keepAlive: true // 需要缓存
		}
	},
	{
		path: '/matchwork',//赛事考试
		name: 'matchwork',
		component: () => import('@/views/matchwork.vue')
	},
	{
		path: '/matchdetail',//赛事考试详情
		name: 'matchdetail',
		component: () => import('@/views/matchdetail.vue')
	},
	{
		path: '/agreement',//协议
		name: 'agreement',
		component: () => import('@/views/agreement.vue')
	},
	{
		path: '/information',
		name: 'information',
		component: () => import('@/views/information.vue'),
		meta: {
		    title: '内容栏目列表',
		    keepAlive: true // 需要缓存
		}
	},
	{
		path: '/infoDetail',
		name: 'infoDetail',
		component: () => import('@/views/infoDetail.vue')
	},
	{
		path: '/login',
		name: 'login',
		component: () => import('@/views/login.vue')
	},
	{
		path: '/mydiscuss',
		name: 'mydiscuss',
		component: () => import('@/views/mydiscuss.vue')
	},
	{
		path: '/discussPage',
		name: 'discussPage',
		component: () => import('@/views/discussPage.vue')
	},
	{
		path: '/discussDetail',
		name: 'discussDetail',
		component: () => import('@/views/discussDetail.vue')
	},
]
const router = new VueRouter({
	mode: 'history',
	base: "",
	routes
})

export default router
var LoginList = ["/","/home","/login","/webschool","/agreement","/information","/infoDetail","/404"]
var whiteList = ["/","/webschool","/questionbank","/mine","/mygroup","/match","/mydiscuss"]
router.beforeEach(async (to, from, next) => {
	// document.title = getPageTitle(to.meta.title)
	//console.log(to)
	if(whiteList.indexOf(to.path)>-1){
		//console.log("SET_CURPAGE")
		store.commit('SET_CURPAGE', to.name)
	}
	if(to.matched.length>0){
		// next()
		if(LoginList.indexOf(to.path)<0){
			if(localStorage.getItem("token")){
				next()
			}else{
				if(to.path=="" || to.path=="/" || to.path=="/404"){
					next(`/`)
				}else{
					//需要登录才能访问的页面
					// next(`/login?redirect=${to.path}`)
					Message.error("请先登录账号！")
					store.commit('SET_CURPAGE', "home")
					next(`/`)
				}
			}
		}else{
			next()
		}
	}else{
		next('/') // 判断此跳转路由的来源路由是否存在，存在的情况跳转到来源路由，否则跳转到404页面
	}
})
// 切换页面滚动回顶部
router.afterEach((to,from,next) => {
	// console.log("11322")
    window.scrollTo(0,0);
    // 或
    // window.scroll(0, 0);
});
// 处理高版本连续跳转的错误
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
	if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
	return originalPush.call(this, location).catch(err => err)
}
