<template>
	<div v-if="isShow" class="audioCom">
		<!-- 音频 -->
		<audio controls ref="commonaudioCom" class="disnone">
			<source src="../../assets/success.mp3" />
		</audio>
	</div>
</template>

<script>
	export default {
		name: 'audioCom',
		props: {
			isShow: {
				type: Boolean,
				default () {
					return true
				}
			},
		},
		data() {
			return {

			}
		},
		mounted() {
			// this.creatQrCode()
		},
		methods: {
			//播放音频
			playAudioCom(src) {
				var _this = this
				try{
					if(src){
						_this.$refs.commonaudioCom.src = src;
					}
					_this.$refs.commonaudioCom.load()
					_this.$refs.commonaudioCom.play()
					// _this.$nextTick(res => {
					//     _this.$refs.commonaudioCom.load()
					//     _this.$refs.commonaudioCom.play()
					// })
				}catch(e){
					//TODO handle the exception
				}
			},


		}
	}
</script>

<style scoped></style>